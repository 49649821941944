const devUrl = 'https://staging-backend.retrobridge.io';
const staging = 'https://staging-backend.retrobridge.io';
const prodUrl = 'https://backend.retrobridge.io';

type StagesType = 'development' | 'staging' | 'production';

const stages: Record<StagesType, string> = {
  development: devUrl,
  staging: staging,
  production: prodUrl,
};

const stage = (process.env.REACT_APP_STAGE as StagesType) || 'development';
export const BASE_URL = stages[stage];
